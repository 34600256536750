/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  display: flex;
}

.main {
  flex-direction: row;
  position: fixed;
  top: 60px;
  max-height: calc(100vh - 60px);
  left: 120px;
  max-width: calc(100vw - 120px);
}

//Copied from https://www.w3schools.com/howto/howto_css_circles.asp
.dot {
  height: 25px;
  width: 25px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorOrange {
  height: 25px;
  width: 25px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorOrange2 {
  height: 25px;
  width: 25px;
  background-color: rgba(255, 166, 0, 0.979);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorRed {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorGreen {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorTransparent {
  height: 25px;
  width: 25px;
  background-color: rgb(70, 243, 70);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dotColorBlack {
  height: 25px;
  width: 25px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

//----------------- Font size -----------------//


mat-option * {
  font-size: 12px !important;
}

a {
  text-decoration: none;
}

.current {
  color: white;

  .mdc-tab__content {
    .mdc-tab__text-label {
      color: white;
      opacity: 1;
    }
  }
}

.mdc-tab__text-label {
  color: white !important;
  opacity: 0.6;
}

.mdc-tab__text-label:hover {
  color: white !important;
}



.menu {
  position: fixed;
  flex-direction: column;
  top: 60px;
  bottom: -60px;
  left: 0;
  right: 0;
  width: 120px;
  padding-top: 10px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  height: 60px;
}

.footer {
  position: fixed;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: row;
}

.pageContent {
  height: calc(100vh - 190px);
  overflow: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}


// ************* Color themes *************
.blue {
  color: white !important;
}

.indigo {
  color: white !important;
}

.black {
  background-color: rgb(0, 0, 0);
  color: white;
}

// ***************** Light tables *****************
.light {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  border: 1px solid rgb(187, 187, 187) !important;
  overflow: auto;
}

.thin-table {
  width: calc(100vw - 160px - 50px);
}

.light th {
  color: black;
  font-size: medium;
  /* margin-top: 10px;
  padding-top: 10px; */
}

.light td {
  border-top: 1px solid rgb(187, 187, 187);
  /* line-height: 50px;
  text-indent: 5px; */
}

.mat-mdc-footer-row,
.mat-mdc-header-row,
.mat-mdc-row {
  display: inline-flex;
  min-width: 100%;
}

table {
  width: 100%;
}

mat-form-field * {
  font-size: 14px;
}


.focusable {
  transition: background-color 0.1s;
}


.thin {
  width: 400px;
}

.Xthin {
  width: 75px;
}


.step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: calc(100vh - 275px);
}

.bottom-buttons {
  width: 100%;
}

.substep {
  margin-bottom: -50px;
}

/* .gr {
  border-top: 1px solid rgb(187, 187, 187);
} */

.mat-mdc-sort-header-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.stick-right {
  text-align: right !important;
}


#app-names-nav .mat-checkbox-inner-container {
  display: none;
}

#app-names-nav .mat-checkbox-layout {
  margin: 0;
  width: 100%;
}

#app-names-nav .mdc-label {
  padding: 12px 7px;
  display: block;
  width: 100%;
  text-align: left;
}

.confirm-dialog-container #non-button {
  width: 30%;
  background-color: #fc7169;
  color: white;
}

.confirm-dialog-container #non-button:hover {
  background-color: #ff8a82;
}

.confirm-dialog-container #oui-button {
  width: 30%;
  background-color: #b6bece;
  color: white;
}

.confirm-dialog-container #oui-button:hover {
  background-color: #c4cddc;
}

.confirm-dialog-container #return-button {
  background-color: #b6bece;
  color: white;
  border-radius: 0px;
}

.data-header {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.data-header mat-form-field,
.data-header mat-label {
  width: calc(100% - 220px);
  margin-right: 20px;
}

.data-header button {
  width: 200px;
  height: 40px;
}

.help-icon {
  font-size: 35pt;
  opacity: 0.7;
}

.help-icon:hover {
  opacity: 1;
}

.flex-grow {
  width: 100%;
}

.alRight {
  display: flex;
  justify-content: flex-end;
}

/***** button *****/
.btn_valid {
  background-color: rgb(29, 185, 15) !important;
}

.btn_edit {
  background-color: rgb(79, 187, 230) !important;
}

.exportBtn button {
  margin-top: 10px;
}

.btRight {
  float: right;
}


/******* SnackBar ********/
.success-snack-bar {
  .mdc-snackbar__surface {
    background-color: #44c154 !important;
  }

  .mdc-snackbar__label,
  .mdc-button__label {
    color: white !important;
  }

  button {
    border: none;
  }

  .mat-icon {
    color: white !important;
  }
}

.error-snack-bar {
  .mdc-snackbar__surface {
    background-color: rgb(255, 82, 82) !important;
  }

  .mdc-snackbar__label,
  .mdc-button__label {
    color: white !important;
  }

  button {
    border: none;
  }

  .mat-icon {
    color: white !important;
  }
}

.custom-snackbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.custom-snackbar button {
  background-color: transparent;
}